import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Logo from '../images/vraa_typo_vector.svg'

const Header = (props) => (
    <header id="neoheader">
        <div className="site-title"><Link to="/"><img src={Logo} width="150" /></Link></div>
        <div className="site-contents">
        <ul>
                <li><Link to="/vraa">What`s VRAA</Link></li>
                <li><Link to="/outline">開催概要</Link></li>
                <li><Link to="/">全提出作品リスト</Link></li>
            </ul>
            </div>
    </header>
)


export default Header